import * as METADATA_KEY from '../constants/metadata_keys';
var Metadata = (function () {
    function Metadata(key, value) {
        this.key = key;
        this.value = value;
    }
    Metadata.prototype.toString = function () {
        if (this.key === METADATA_KEY.NAMED_TAG) {
            return "named: ".concat(String(this.value).toString(), " ");
        }
        else {
            return "tagged: { key:".concat(this.key.toString(), ", value: ").concat(String(this.value), " }");
        }
    };
    return Metadata;
}());
export { Metadata };
